<template>
  <div>
    <trac-back-button
      v-if="!isEmbeddedModal"
      @click.native="$router.push({ name: 'LoanMain' })"
    >
      Back
    </trac-back-button>

    <div class="lg:w-3/5 mt-10 mx-auto load-shadow rounded-md p-10">
      <h1 class="text-primaryBlue text-lg font-medium">
        New to Traction and need a loan?
      </h1>
      <p class="pt-1 text-gray-700 text-sm">
        Does your Business meet the following criteria
      </p>

      <div class="mt-10 flex flex-col">
        <trac-checkbox @check="checkTermOne" :value="true" data-test="checkbox-1">
          <div class="text-sm pl-5 text-gray-800">
            My Business makes at least N400,000 in Transactions every month
          </div>
        </trac-checkbox>

        <trac-checkbox @check="checkTermTwo" :value="true" class="mt-5" data-test="checkbox-2">
          <div class="text-sm pl-5 text-gray-800">
            I am willing to immediately sign up for a Traction POS Terminal
          </div>
        </trac-checkbox>
        <div class="flex justify-center items-center mt-10 w-full">
          <trac-button
          data-test="apply-now"
            @click.native="toggle"
            :disabled="!checkboxOne || !checkboxTwo"
          >
            <div class="px-2 w-56">Apply Now</div>
          </trac-button>
        </div>
      </div>
      <div class="text-xs text-gray-800 text-center pt-5">
        Want to use your history with Traction to determine your loan limit?
        <span
          class="text-primaryBlue cursor-pointer"
          @click="$router.push({ name: 'LoanLanding' })"
          >Click Here</span
        >
      </div>
    </div>
    <trac-modal v-if="isEmbeddedModal" size=" max-w-5xl" :cancel="false">
      <div class="absolute top-0 mt-3 mr-3 right-0">
        <svg
          @click="isEmbeddedModal = false"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div id="formTwo" class="mt-5" style="width: 100%; height: 75vh"></div>
    </trac-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkboxOne: false,
      checkboxTwo: false,
      isEmbeddedModal: false,
    };
  },
  methods: {
    checkTermOne(e) {
      this.checkboxOne = e.checked;
    },
    checkTermTwo(e) {
      this.checkboxTwo = e.checked;
    },
    toggle() {
      this.isEmbeddedModal = true;
      this.$nextTick(() => {
        const container = document.querySelector('#formTwo');
        window.tf.createWidget('jZ6CaAVR', {
          container,
          // hideHeaders: true,
          // hideFooter: true,
          opacity: 1,
          // hidden: {
          //   article_url: window.location.href,
          // },
        });
      });
    },
  },
};
</script>

<style>
.load-shadow {
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.25);
}
</style>
